const replacements: { [key: string]: string } = {
	" L'": " Le ",
	" C'": " Ceci ",
	" D'": " De ",
	" J'": " Je ",
	" M'": " Me ",
	" N'": " Ne ",
	" Qu'": " Que ",
	" S'": " Se ",
	" S'il ": " Si il ",
	" S'ils ": " Si ils ",
	" T'": " Te ",
	" Jusqu'": " Jusque ",
	" Lorsqu'": " Lorsque ",
	" Puisqu'": " Puisque ",
	" Quelqu'": " Quelque ",
	" Quoiqu'": " Quoique ",
	" l'": " le ",
	" c'": " ceci ",
	" d'": " de ",
	" j'": " je ",
	" m'": " me ",
	" n'": " ne ",
	" qu'": " que ",
	" s'": " se ",
	" s'il ": " si il ",
	" s'ils ": " si ils ",
	" t'": " te ",
	" jusqu'": " jusque ",
	" lorsqu'": " lorsque ",
	" puisqu'": " puisque ",
	" quelqu'": " quelque ",
	" quoiqu'": " quoique ",
	" av. ": " avant ",
	" janv. ": " janvier ",
	" fevr. ": " fevrier ",
	" avr. ": " avril ",
	" sept. ": " septembre ",
	" oct. ": "octobre ",
	" juil. ": " juillet ",
	" nov. ": " novembre ",
	" dec. ": " decembre ",
	" M. ": " monsieur ",
	" Mr. ": " monsieur ",
	" Dr. ": " docteur ",
	" Mme ": " madame ",
	" Mme.": " madame ",
	" Mlle ": " mademoiselle ",
	" St. ": " saint ",
	" St ": " saint ",
	" n°": " numero ",
	" d°": " degres ",
	" env. ": " environ ",
	" dsl ": " desole ",
	" bjr ": " bonjour ",
	" & ": " et ",
};

export const nlpReplaceItems = (tokens: string) => {
	try {
		// tokens = ` ${tokens} `
		tokens = tokens.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		let reg;
		for (let rp in replacements) {
			reg = new RegExp(rp, "g");
			tokens = tokens.replace(reg, replacements[rp]);
		}

		tokens = tokens.replace(/å/g, "aa");
		tokens = tokens.replace(/ø/g, "o");
		tokens = tokens.replace(/ð/g, "o");
		tokens = tokens.replace(/ß/g, "b");
		tokens = tokens.replace(/ʿ/g, "'");
		tokens = tokens.replace(/ʻ/g, "'");
		tokens = tokens.replace(/ə/g, "e");
		tokens = tokens.replace(/þ/g, "b");
		tokens = tokens.replace(/‘/g, "'");
		tokens = tokens.replace(/đ/g, "d");
		tokens = tokens.replace(/ı/g, "i");
		tokens = tokens.replace(/ħ/g, "h");
		tokens = tokens.replace(/æ/g, "ae");
		tokens = tokens.replace(/œ/g, "oe");
		tokens = tokens.replace(/Å/g, "Aa");
		tokens = tokens.replace(/Å/g, "Aa");
		tokens = tokens.replace(/å/g, "aa");
		tokens = tokens.replace(/’/g, "'");
		tokens = tokens.replace(/ł/g, "l");
		tokens = tokens.replace(/Ł/g, "L");
		tokens = tokens.replace(/—/g, "-");

		return tokens.toLowerCase();
	} catch (e) {
		console.error(e);
	}
	return tokens;
};
